import * as React from "react";
import { FunctionComponent, useCallback, useEffect } from "react";
import { Button } from "@chakra-ui/react";
import { navigate } from "gatsby";
import * as styles from "./navigation-mobile.module.css";

type NavigationMobileType = {
  onClose?: () => void;
};

const NavigationMobile: FunctionComponent<NavigationMobileType> = ({
  onClose,
}) => {
  const onLogoClick = useCallback(() => {
    navigate("/");
  }, []);

  const onButton1Click = useCallback(() => {
    navigate("/about-us");
  }, []);

  const onButton3Click = useCallback(() => {
    navigate("/sign-in-page");
  }, []);

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add(styles.animate);
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);

  return (
    <div className={styles.navigationMobile} data-animate-on-scroll>
      <div className={styles.navigation}>
        <a className={styles.logo} onClick={onLogoClick}>
          <img
            className={styles.tcpSupplyLogoIcon}
            alt=""
            src="../tcpsupply-logo3.svg"
          />
        </a>
        <Button className={styles.button} variant="ghost" colorScheme="tcpcyan">
          Products
        </Button>
        <Button
          className={styles.button1}
          variant="ghost"
          colorScheme="tcpcyan"
          onClick={onButton1Click}
        >
          About Us
        </Button>
        <Button className={styles.button} variant="ghost" colorScheme="tcpcyan">
          Contact Us
        </Button>
        <Button
          className={styles.button1}
          variant="solid"
          colorScheme="tcpcyan"
          onClick={onButton3Click}
        >
          Sign In
        </Button>
      </div>
    </div>
  );
};

export default NavigationMobile;
