import * as React from "react";
import { FunctionComponent, useState, useCallback, useEffect } from "react";
import {
  Button,
  Link
} from "@chakra-ui/react";
import { HamburgerIcon, PhoneIcon } from "@chakra-ui/icons";
import { navigate } from "gatsby";
import NavigationMobile from "../components/navigation-mobile";
import PortalDrawer from "../components/portal-drawer";
import * as styles from "./index.module.css";
import Layout from "../components/Base/Layout";
import useAuth from "../components/useAuth";
import ContactForm from "../components/Contact/ContactForm";
import { SEO } from "../components/Base/Seo";

const Home: FunctionComponent = () => {
  const [isNavigationMobileOpen, setNavigationMobileOpen] = useState(false);

  const { loggedIn, user } = useAuth();

  const onButtonClick = useCallback(() => {
    navigate('/shop');
  }, []);

  const onButton2Click = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='contactSectionContainer']"
    );
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onButton3Click = useCallback(() => {
    navigate("/log-in");
  }, []);

  const openNavigationMobile = useCallback(() => {
    setNavigationMobileOpen(true);
  }, []);

  const closeNavigationMobile = useCallback(() => {
    setNavigationMobileOpen(false);
  }, []);

  const onButton4Click = useCallback(() => {
    navigate("/about-us");
  }, []);

  const onButton6Click = useCallback(() => {
    navigate("/about-us");
  }, []);

  const onButton7Click = useCallback(() => {
    navigate("/about-us");
  }, []);

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add(styles.animate);
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);

  return (
    <Layout>
      <div className={styles.home}>
        <div className={styles.heroSection} data-animate-on-scroll>
          <div className={styles.heroContent}>
            <img className={styles.patternsIcon} alt="" src="/patterns.svg" />
            <img className={styles.tcplogoIcon} alt="" src="/tcplogo.svg" />
            <div className={styles.heroText}>
              <div className={styles.title}>
                <div className={styles.display}>Welcome to TCP Supply</div>
              </div>
            </div>
            <div className={styles.heroActions}>
            <Link href="/about-us" _hover={{ textDecoration:"none"}}> 
              <Button
                className={styles.button}
                variant="outline"
                colorScheme="tcpcyan"
                size="lg"
                onClick={onButton4Click}
              >
                About Us
              </Button>
            </Link>
            <Link href="/shop" _hover={{ textDecoration:"none"}}>
              <Button variant="solid" colorScheme="tcpcyan" size="lg">
                Start Shopping
              </Button>
            </Link>  
            </div>
          </div>
        </div>
        <div className={styles.cardsSection}>
          <div className={styles.sectionTitle}>
            <h2 className={styles.display1}>What We Offer</h2>
          </div>
          <div className={styles.cardsRow}>
            <div className={styles.cardSlot}>
              <div className={styles.card}>
                <div className={styles.cardContent}>
                  <div className={styles.cardLabel}>
                    <b className={styles.label}>
                      A one stop solution for all your medical needs
                    </b>
                  </div>
                <Link href="/about-us" _hover={{ textDecoration:"none"}}>
                  <Button
                    className={styles.button}
                    variant="solid"
                    colorScheme="tcpcyan"
                    onClick={onButton6Click}
                  >
                    Learn More
                  </Button>
                </Link>
                </div>
                <div className={styles.cardImg}>
                  <img
                    className={styles.depositphotos349498906XL1Icon}
                    alt=""
                    src="/depositphotos-349498906-xl-1@2x.png"
                  />
                </div>
              </div>
            </div>
            <div className={styles.cardSlot1}>
              <div className={styles.card}>
                <div className={styles.cardContent}>
                  <div className={styles.cardLabel}>
                    <b className={styles.label}>Wholesale Medical Equipement</b>
                  </div>
                <Link href="/about-us" _hover={{ textDecoration:"none"}}> 
                  <Button
                    className={styles.button}
                    variant="solid"
                    colorScheme="tcpcyan"
                    onClick={onButton7Click}
                  >
                    Learn More
                  </Button>
                </Link>  
                </div>
                <div className={styles.cardImg1}>
                  <img
                    className={styles.depositphotos349498906XL1Icon1}
                    alt=""
                    src="/depositphotos-349498906-xl-11@2x.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.categoriesSection}>
          <div className={styles.sectionTitle}>
            <div className={styles.display2}>Product Categories</div>
          </div>
          <div className={styles.categoryCards}>
            <div className={styles.catCardRow}>
              <div className={styles.catCardSlot}>
                <div className={styles.categoryCard}>
                  <img
                    className={styles.imageIcon}
                    alt=""
                    src="/image@2x.png"
                  />
                  <div className={styles.cardTitle}>
                    <div
                      className={styles.label2}
                    >{`Clinic & Private Practice`}</div>
                    <Button
                      className={styles.button6}
                      variant="solid"
                      colorScheme="tcpcyan"
                      onClick={onButtonClick}
                    >
                      View Products
                    </Button>
                  </div>
                </div>
              </div>
              <div className={styles.catCardSlot}>
                <div className={styles.categoryCard}>
                  <img
                    className={styles.imageIcon}
                    alt=""
                    src="/image1@2x.png"
                  />
                  <div className={styles.cardTitle}>
                    <div className={styles.label2}>Secondary Items</div>
                    <Button
                      className={styles.button6}
                      variant="solid"
                      colorScheme="tcpcyan"
                      onClick={onButtonClick}
                    >
                      View Products
                    </Button>
                  </div>
                </div>
              </div>
              <div className={styles.catCardSlot}>
                <div className={styles.categoryCard}>
                  <img
                    className={styles.imageIcon}
                    alt=""
                    src="/image2@2x.png"
                  />
                  <div className={styles.cardTitle}>
                    <div className={styles.label2}>Clinic Instruments</div>
                    <Button
                      className={styles.button6}
                      variant="solid"
                      colorScheme="tcpcyan"
                      onClick={onButtonClick}
                    >
                      View Products
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.catCardRow}>
              <div className={styles.catCardSlot}>
                <div className={styles.categoryCard}>
                  <img
                    className={styles.imageIcon}
                    alt=""
                    src="/image3@2x.png"
                  />
                  <div className={styles.cardTitle}>
                    <div className={styles.label2}>
                      Personal Protective Instruments
                    </div>
                    <Button
                      className={styles.button6}
                      variant="solid"
                      colorScheme="tcpcyan"
                      onClick={onButtonClick}
                    >
                      View Products
                    </Button>
                  </div>
                </div>
              </div>
              <div className={styles.catCardSlot}>
                <div className={styles.categoryCard}>
                  <img
                    className={styles.imageIcon}
                    alt=""
                    src="/image4@2x.png"
                  />
                  <div className={styles.cardTitle}>
                    <div className={styles.label2}>Home Careline</div>
                    <Button
                      className={styles.button6}
                      variant="solid"
                      colorScheme="tcpcyan"
                      onClick={onButtonClick}
                    >
                      View Products
                    </Button>
                  </div>
                </div>
              </div>
              <div className={styles.catCardSlot}>
                <div className={styles.categoryCard}>
                  <img
                    className={styles.imageIcon}
                    alt=""
                    src="/image5@2x.png"
                  />
                  <div className={styles.cardTitle}>
                    <div className={styles.label2}>Special Orders</div>
                    <Button
                      className={styles.button6}
                      variant="solid"
                      colorScheme="tcpcyan"
                      onClick={onButtonClick}
                    >
                      View Products
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.whySection}>
          <div className={styles.featuresRow}>
            <div className={styles.features}>
              <div className={styles.sectionTitle2}>
                <h2 className={styles.display3}>Why Us</h2>
              </div>
              <div className={styles.featuresRow1}>
                <div className={styles.featureSlot}>
                  <div className={styles.feature}>
                    <div className={styles.iconBox}>
                      <img
                        className={styles.medicineSyrupIcon}
                        alt=""
                        src="/medicinesyrup.svg"
                      />
                    </div>
                    <div className={styles.featureLabel}>
                      <div className={styles.label8}>Large Product Range</div>
                    </div>
                  </div>
                </div>
                <div className={styles.featureSlot}>
                  <div className={styles.feature}>
                    <div className={styles.iconBox}>
                      <img
                        className={styles.medicineSyrupIcon}
                        alt=""
                        src="/premiumrights.svg"
                      />
                    </div>
                    <div className={styles.featureLabel}>
                      <div className={styles.label8}>Low Prices</div>
                    </div>
                  </div>
                </div>
                <div className={styles.featureSlot}>
                  <div className={styles.feature}>
                    <div className={styles.iconBox}>
                      <img
                        className={styles.medicineSyrupIcon}
                        alt=""
                        src="/ship.svg"
                      />
                    </div>
                    <div className={styles.featureLabel}>
                      <div className={styles.label8}>Fast Delivery</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.featuresRow1}>
                <div className={styles.featureSlot}>
                  <div className={styles.feature}>
                    <div className={styles.iconBox}>
                      <img
                        className={styles.medicineSyrupIcon}
                        alt=""
                        src="/shieldcheck.svg"
                      />
                    </div>
                    <div className={styles.featureLabel}>
                      <div className={styles.label8}>Trusted Brands</div>
                    </div>
                  </div>
                </div>
                <div className={styles.featureSlot}>
                  <div className={styles.feature}>
                    <div className={styles.iconBox}>
                      <img
                        className={styles.medicineSyrupIcon}
                        alt=""
                        src="/headset.svg"
                      />
                    </div>
                    <div className={styles.featureLabel}>
                      <div className={styles.label8}>Customer Service</div>
                    </div>
                  </div>
                </div>
                <div className={styles.featureSlot}>
                  <div className={styles.feature}>
                    <div className={styles.iconBox}>
                      <img
                        className={styles.medicineSyrupIcon}
                        alt=""
                        src="/world.svg"
                      />
                    </div>
                    <div className={styles.featureLabel}>
                      <div className={styles.label8}>Global Reach</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.whyImg}>
              <div className={styles.image}>
                <img
                  className={styles.depositphotos349498186S1Icon}
                  alt=""
                  src="/depositphotos-349498186-s-1@2x.png"
                />
              </div>
            </div>
          </div>
          <img className={styles.patternsIcon1} alt="" src="/patterns1.svg" />
        </div>
        <div
          className={styles.contactSection}
          data-scroll-to="contactSectionContainer"
          id="contactSectionContainer"
        >
          <div className={styles.contactDetailsSignIn}>
            <img
              className={styles.patternsIcon2}
              alt=""
              src="/patterns2.svg"
            />
            <div className={styles.contactDetails}>
              <div className={styles.title1}>
                <div className={styles.display4}>Need Anything?</div>
              </div>
              <div className={styles.visibleDetails}>
                <div className={styles.details}>
                  <div className={styles.label8}>Call us:</div>
                  <b className={styles.label15}><Link href="tel:18885741440">+1-888-574-1440</Link></b>
                </div>
                {/* <div className={styles.details}>
                  <div className={styles.label8}>Email us:</div>
                  <b className={styles.label15}>email@website.com</b>
                </div> */}
                <div className={styles.details}>
                  <div className={styles.label8}>Monday to Friday</div>
                  <b className={styles.label15}>9AM - 5PM EST</b>
                </div>
              </div>
              <div className={styles.signInBtn}>
                <Button
                  className={styles.button6}
                  variant="solid"
                  colorScheme="tcpcyan"
                  onClick={onButton3Click}
                >
                  Sign In
                </Button>
              </div>
              <div className={styles.label20}>
              <div className={styles.label21}>
                {!loggedIn ? (
                  <div className={styles.labelDiv9}>
                    Please sign in for address
                  </div>
                ) : (
                  <div className={styles.labelDiv9}>TCP Supply</div>
                )}
              </div>
              </div>
              <div className={styles.ccs}>
                <img className={styles.visaIcon} alt="" src="/visa.svg" />
                <img
                  className={styles.visaIcon}
                  alt=""
                  src="/mastercard.svg"
                />
              </div>
            </div>
          </div>
          <ContactForm />
        </div>
        
      </div>
      {isNavigationMobileOpen && (
        <PortalDrawer
          overlayColor="rgba(0, 0, 0, 0.5)"
          placement="Top"
          onOutsideClick={closeNavigationMobile}
        >
          <NavigationMobile onClose={closeNavigationMobile} />
        </PortalDrawer>
      )}
    </Layout>
  );
};

export default Home;

export const Head = () => (
  <SEO title="TCP Supply" description="home" />
)